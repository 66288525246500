import React from 'react'
import Layout from '../Layout'
import View from '../View'
import { getCampaignLogs } from '../../utils/email'
import { DataGrid } from '@mui/x-data-grid'
import Moment from 'moment'
import { Link } from 'gatsby'
import WaitLoader from '../WaitLoader'

class EmailCampaignLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: [],
      errorMessage: '',
      loading: true,
      columns: [
        {
          field: 'sentDate', headerName: 'Sent Date', width: 100,
          renderCell: (params) => (
            <strong>
              {Moment(params.row.sent)
              .local()
              .format("MM-DD-YYYY hh:mm:ss a")}
            </strong>
          )
        },
        {
          field: 'name', headerName: 'Name', width: 200
        },
        {
          field: 'subject', headerName: 'Subject', width: 350
        },
        // {
        //   field: 'delivered', headerName: 'Delivered', width: 70
        // },
        // {
        //   field: 'opened', headerName: 'Opened', width: 70
        // },
        // {
        //   field: 'failed', headerName: 'Failed', width: 70
        // },
        // {
        //   field: 'clicked', headerName: 'Clicked', width: 70
        // },
        {
          field: 'viewLink', headerName: 'View', width: 175,
          renderCell: (params) => (
            <strong>
              <Link
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                to={'/email/campaign/log/'+params.row._id}
              >
                View Campaign Logs
              </Link>
            </strong>
          )
        }
      ]
    }
  }

  componentDidMount() {
    getCampaignLogs(function(err, response){
      if(err){
        console.log(err)
        this.setState({errorMessage: err.message, loading: false})
      }else{
        response.forEach((item, i) => {
          let distinctOpens = []
          item.events?.filter(event => event.eventType === 'opened').forEach((open,i)=>{
            if(!distinctOpens.find(distincto => distincto.recipient === open.recipient)){
              distinctOpens.push(open)
            }
          })
          item.failed = item.events?.filter(event => event.eventType === 'failed').length
          item.opened = distinctOpens.length
          item.delivered = item.events?.filter(event => event.eventType === 'delivered').length
          item.clicked = item.events?.filter(event => event.eventType === 'clicked').length
        })
        this.setState({ logs: response, loading: false })
      }
    }.bind(this))
  }

  render() {
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    return (
      <Layout>
      <View title="SENT EMAIL CAMPAIGNS">
      <div className="row col-xs-12">
      <p>Your sent email campaigns will appear in the list below. You can drill down each individual campaign logs by clicking on 'View Campaign Logs', down to individual email logs.</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12 relative">
          <WaitLoader loading={this.state.loading} />
          {errorMessage}
          <div style={{ display: 'flex', height: '100vh' }}>
          <div style={{ flexGrow: 1 }}>
          <DataGrid rows={this.state.logs} columns={this.state.columns} getRowId={(row) => row._id} height="500" />
          </div></div>
        </div>
      </div>
      </View>
      </Layout>
    )
  }
}

export default EmailCampaignLog
